.custom-checkbox, .custom-radio    {
  padding-left: 34px!important; height: 23px;

  input {
    opacity: 0;
    margin-left: -29px!important;
  }
}
.custom-radio   {
  background: url(../img/radio-off.png) no-repeat;
}

.has-error {
  .custom-radio    {
    background: url(../img/radio-error.png) no-repeat;
  }
}
.custom-checkbox    { background: url(../img/check-off.png) no-repeat; }

.has-error {
  .custom-checkbox     {
    background: url(../img/check-error.png) no-repeat;
  }
}

label.c_on      { background: url(../img/check-on.png) no-repeat!important; }
label.r_on      { background: url(../img/radio-on.png) no-repeat!important; }
