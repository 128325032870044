/* colors */

@grey100: #393939;
@grey100_text: #c4cbd0;

@grey60: #555656;
@grey60_text: @grey100_text;
@grey60_textneg: #979EA3;
@grey58: #585858;

@brig_blue: #0066b0;
@brig_blue_dark: #3379bb;

@text_gray: #5a5a5a;

@newsletterbg: #414345;
@newsletter_text: #82929c;
@newsletter_h3: #d2dfe7;

@footer_muted: #555f66;
@footer_text: #82929c;


.preload header * {
  transition: none!important;
}

/* generat overwrite */

input, textarea, select, button, .btn {

  &, &.form-control {
    border-radius: 0px;
  }

  box-shadow: none!important;
}

button .icon {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  display: block;
}

.table > tbody > tr > td:first-of-type {
  padding-left: 0;
}

.table > tbody > tr:first-of-type td {
  border-top: 0;
}

.table {
  margin-bottom: 0;

  tbody tr td {
    padding-top: 15px;
    padding-bottom: 15px;

    border-top: 1px solid #e6e8ec;
  }

  &.plain {
    tbody tr td {
      padding-top: 5px;
      padding-bottom: 5px;

      border: 0;
    }
  }
}


body {
  color: @text_gray;
}

a:focus {
  outline: none;
}

.btn {
  padding: 7px 20px;
  padding-top: 8px;
  transition: all 0.1s;
}

h3 {
  font-weight: 200;
  @media screen and (min-width: @screen-lg) {
    font-weight: 100;
  }
  font-family: 'Raleway', sans-serif;

}

ul:not(.nav) {
  padding: 0;

  li {
    background: url(../img/arrow_small.png) no-repeat left top;
    padding: 0px 0px 15px 15px;

    @media screen and (min-width: @screen-lg) {
      margin-bottom: 10px;
    }

    list-style: none;
    margin: 0;
    background-position: 0px 3px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.check {

    li {
      background-image: url(../img/check_ul.png);
      padding-left: 25px;
      margin-bottom: 0;
    }
  }
}

ul.plain, ol {

  padding-left: 17px;

  li {
    padding: 0px 0px 5px 5px;
    margin: 0;
  }
}

ul.plain {
  li {
    list-style: square;
    background: none;
  }
}

.checkbox label {
  font-weight: inherit;
}

textarea {
  resize: none;
}

.radio-inline {
  font-weight: 300;
}

input, input.form-control, select, select.form-control, textarea, textarea.form-control {
  &:focus {
    border: 1px solid #b3b3b3;
  }
}

/* Elements */

.arrowlink, .arrowlink-back {
  padding-left: 20px;
  background: url(../img/arrow_small.png) no-repeat left top;
  background-position-y: 3px;

  &.arrowlink-back {
    background-image: url(../img/arrow_small_back.png);
  }
}

.pad_bot5 {
  padding-bottom: 5px!important;
}

.pad_top0 {
  padding-top: 0!important;
}

.pad_bot25 {
  padding-bottom: 25px;
}

.mar_bot50 {
  margin-bottom: 50px;
}

.pad_lef20 {
  padding-left: 20px;
}

.icon-large {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%;
}

.icon-xlarge {
  font-size: 2.5em;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row-full-width
{
  margin-left: 0 !important;
  margin-right: 0 !important;
}


.text-center-xs {
  @media screen and (max-width: @screen-sm-1) {
    text-align: center;
  }
}


.icon.icon-brid {
  -webkit-animation-duration: 3s;
  -webkit-animation-name: rotateIcon;
  transform: rotate(-15deg); // Fix for IE 9
}

@-webkit-keyframes rotateIcon {
  from {transform: rotate(0)}
  to {transform: rotate(-15deg)}
}

.imagetogray {

  img {

    opacity: 0.75;
    filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+, Firefox on Android */
    -webkit-filter: grayscale(100%);
    transition: all 0.2s;

    &:hover {
      opacity: 1;
      filter: grayscale(0%);
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(0%);
    }

  }
}

/* Input */

.grey, .white, .red {
  &, input, .input-group-addon, .input-group-btn button {
    background-color: @grey60;
    border: 0;
    color: @grey60_text;

  }

  .input-group-btn button {
    font-size: 12px;
    padding: 0;
    height: 100%;
    li {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
    }
  }

  &:focus, input:focus {
    border-color: @grey60_text;
  }

  &, input {
    .placeholder(@grey60_textneg);
  }
}

.white {
  &, input, .input-group-addon {
    background: #ffffff;
    color: @grey100;
  }

  .input-group-btn button {
    background-color: #82929c;
  }
}

form .redstar {
  color: #e72a46;
}

/* Button */

.btn.full-weight {
  width: 100%;
}

.btn-grey {
  background-color: #82929c;
  color: #ffffff;
  font-size: @font-size-small;
  font-weight: bold;

  &:hover {
    background: @brig_blue;
    color: #ffffff;
  }
}

.btn-red {
  background: #e72a46;
  color: #ffffff;

  &:hover {
    background: @brig_blue;
    color: #ffffff;
  }
}

/* Container */
.container {
  max-width: @screen-xs;
  padding-right: 0;
  padding-left: 0;

  &.inner {
    margin: 0 auto;
  }

  > .maincontent.metrogrid {
    @media screen and (max-width: @screen-sm-1) {
      margin-left: 2px;
      margin-right: 2px;
    }

    @media screen and (min-width: @screen-md) {
      padding-left: 15px;
    }

    @media screen and (min-width: @screen-lg) {
      padding-left: 115px;
    }


  }

  .metrogrid h3 {
    margin-bottom: 10px!important;
  }
}

@media screen and (min-width: @screen-sm) {
  .container {
    width: 897px; //-3px
    max-width: 897px;
  }
}

@media screen and (min-width: @screen-lg) {
  .container {
    width: 1236px; // -3px;
    max-width: 1236px;
  }
}
@media screen and (min-width: @screen-md) {
  header {
    height: 40px;
  }
}

.header {

  transition: all 0.5s;
  > .container > .row:first-of-type {
    transition: all 0.4s;
    max-height: 50px;
  }

  &.movein {
    background: none;
    > .container .row:first-of-type {
      max-height: 0px;
      overflow: hidden;
    }

    .search {
      max-height: 0px;
    }
  }

  @media screen and (max-width: @screen-sm-1) {
    display: none;
  }

  background-color: @grey100;
  color: @grey100_text;
  font-size: @font-size-small;
  font-weight: 300;
  height: 40px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 40px;
    margin-left: 20px;
    font-weight: 300;
    font-size: @font-size-small;
    @media screen and (min-width: @screen-lg) {
      margin-left: 27px;
    }
  }

  .search {

    width: 140px;
    @media screen and (min-width: @screen-lg) {
      width: 215px;
    }

    button:hover {
      color: #ffffff;
    }

    margin-top: 5px;
    margin-right: 30px;
    input, .input-group-addon {
      font-size: @font-size-small;
      border: 0;
    }
    input {
      height: 100%;
    }

    max-height: 100px;

    border: 1px solid transparent;
    &.focus {
      border: 1px solid #8c8c8c;
    }

    transition: all 0.5s;

  }

  .nav.meta-nav {
    margin-right: 20px;
    & > li > a {
      padding: 10px 10px;
      color: @grey100_text;

      &:hover {
        background-color: @grey58;
      }
    }
  }

  .main-nav {
    width: 100%;
    position: absolute;
    background-color: rgba(235, 240, 250, 0.85);
    height: 80px;

    /* IE 8 hack */
    background: #ffffff\9;
    left: 0;

    .nav > li {
      position: inherit;
      &:first-child {
        margin-left: 10px;
      }

      > a {
        text-transform: uppercase;
        font-weight: bold;
        padding-top: 31px;
        padding-bottom: 32px;

        border-radius: 0;
        color: @text_gray;

        padding-left: 11px;
        padding-right: 11px;

        @media screen and (min-width: @screen-lg) {
          padding-left: 17px;
          padding-right: 17px;
        }

        &:hover {
          background: #ffffff;
        }

      }

      &:hover:not(.logo) {
        text-decoration: none;
        background-color: #ffffff;

        .submenu {
          opacity: 1;
          max-height: 400px;
          border-bottom: 1px solid #dadada;
        }
      }

      &.active a {
        background-color: rgba(255, 255, 255, .5);
        color: @brig_blue;
      }

    }

    .submenu {
      background-color: rgba(255, 255, 255, 0.95);
      max-height: 0px;
      position: absolute;
      width: 100%;
      left: 0px;
      opacity: 0;
      overflow: hidden;
      border: 0;
      transition: all .25s ease-in-out;
      margin: 0;

      h4 {
        text-transform: uppercase;
      }

      > div {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 10px;
        padding-left: 5px;
      }

      > div > div[class^='col-'] {
        border-left: 1px solid #dadada;
        float: none;
        height: 100%;
        display: table-cell;
        vertical-align: top;

        &:first-of-type {
          border-left: 0;
        }
      }

      ul {
        margin-left: 35px;
        li {
          background: 0;
          padding-left: 0;
          color: @text_gray;
          margin-left: -30px;
          padding-bottom: 5px;
        }
      }

      h4 {
        margin-left: 5px;
        font-size: @font-size-small;
        font-weight: bold;
        margin-top: 0;
      }

      a {
        color: #585858;

      }

    }

    .logo {
      margin-top: 25px;
      margin-right: 30px;
    }
  }

  // Clear postition after header
  & + div > div[class^='col-'] {
    position: inherit;
  }
}

footer {

  font-weight: 300;

  .newsletter, .seo_navi {

    & > div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .newsletter {
    background: @newsletterbg;

    padding-top: 0px;
    padding-bottom: 15px;

    @media screen and (min-width: @screen-md) {
      padding-top: 15px;
      padding-bottom: 25px;
    }

    color: @newsletter_text;

    h3 {
      text-transform: uppercase;
      color: @newsletter_h3;

      margin-bottom: 10px;

      @media screen and (min-width: @screen-md) {
        margin-bottom: 5px;
      }

      font-size: 20px;
      @media screen and (min-width: @screen-lg) {
        font-size: 25px;
      }
    }

    input[type='submit'] {
      @media screen and (max-width: @screen-sm-1) {
        margin-top: -2px;
      }
    }

    input[type='text'] {

      @media screen and (max-width: @screen-sm-1) {
        display: inline-block;
        width: 45%;
      }

      &:focus {
        border: 0;
      }

      width: 65%;
      margin-right: 5px;
    }

    a {
      color: @newsletter_text;
      text-decoration: underline;
      &:hover {
        color: @brig_blue_dark;
      }
    }
  }

  .seo_navi {

    background-color: @grey100;
    color: @footer_text;

    a, a:hover {
      color: #ffffff;
    }

    .menu {

      padding-top: 15px;

      margin-bottom: -25px; // for nav-padding

      @media screen and (min-width: @screen-md) {
        padding-top: 35px;
      }

      @media screen and (min-width: @screen-lg) {
        padding-top: 45px;
      }

      ul.nav:not(.sub) > li {
        padding-bottom: 30px;

        text-transform: uppercase;
        @media screen and (min-width: @screen-md) {
          color: #ffffff;
        }

        > strong {
          display: block;

          span.icon {
            font-size: 30px;
            margin-top: -6px;
          }
        }

        @media screen and (max-width: @screen-sm-1) {
          border-bottom: 1px solid #47494a;
          padding-bottom: 15px;
          margin-bottom: 15px;
          margin-left: -15px;
          padding-left: 30px;
          margin-right: -15px;
          padding-right: 30px;
        }
      }

      ul.sub {
        text-transform: initial;
        padding-top: 5px;

        @media screen and (min-width: @screen-md) {
          height: 100%!important;
        }

        @media screen and (min-width: @screen-sm) {
          display: block;
        }

        > li {
          padding-bottom: 0;
        }

        .icon {
          margin-right: 10px;
        }

        a {
          padding: 5px 5px;
          padding-bottom: 2px;
          padding-left: 0;
          font-size: @font-size-small;
          color: @footer_text;

          @media screen and (min-width: @screen-lg) {
            padding-bottom: 5px;
          }

          &:hover {
            background: none;
            text-decoration: underline;
            color: @brig_blue_dark;
          }
        }
      }

    }

    hr {
      border-top: 1px solid #47494a;
      margin-top: 40px;
    }

    .abbinder {

      padding-bottom: 20px;
      font-size: @font-size-small;

      img {
        margin-right: 5px;
      }

      .muted {
        color: @footer_muted;
      }

      a {
        color: @footer_text;
        @media screen and (min-width: @screen-md) {
          margin-left: 15px;
        }

        &:hover {
          color: @brig_blue_dark;
        }
      }
    }

    // if Subsite
    &.subsite {
      @media screen and (max-width: @screen-sm-1) {
        .menu.row {
          display: none;
        }

        .row.abbinder {
          padding-top: 20px;
        }
      }

    }

  }
}

.header_mobile {

  @media screen and (min-width: @screen-sm) {
    display: none;
  }

  h2 {
    display: none;
  }

  .menutoggle {
    font-size: 2.5em;
  }

  background: #ebf0fa;
  padding-top: 20px;
  padding-bottom: 20px;

  > .row:first-of-type {
    display: table;

    > div {
      float: none;
      display: table-cell;
      vertical-align: middle;
    }

  }

  // Clear slider

  & + div .row:first-of-type {
    margin-right: 0;
  }

  & + div > div > div[class^='col-'] {
    padding-right: 0;
  }

  .row {
    margin-right: 0;
  }



  .menuout {
    position: absolute;
    padding-top: 10px;
    z-index: 1500;

    form {
      padding: 10px;
      padding-right: 0;
    }

    .row {
      background: @grey100;
    }

    .row:first-of-type{
      background: #ebf0fa;
    }

    ul.nav {
      padding-bottom: 10px;
      padding-top: 10px;
      margin-right: -15px;

      a {
        color: @newsletter_text;
        font-weight: bold;
        text-transform: uppercase;

        &.active {
          background: #484b4d;
          margin: 15px;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }

  }

  > div div.logo {
    padding-right: 0;

    img {
      width: 100%;
    }
  }
}

/* Metro-grid */

.metrogrid, .metrogrid-inner3, .metrogrid-inner4 {

  margin: 2px;
  color: @grey58;

  @media screen and (max-width: @screen-sm-1) {
    > div.col-xs-12 > div.content {
      height: 195px;
    }
  }


  .metro-white, .metro-blue, .metro-lightblue, .metro-image {
    padding: 0;
    font-weight: 300;

    .content {
      margin: 2px;
      height: 170px;

      @media screen and (min-width: @screen-sm) {
        height: 224px;
      }

      @media screen and (min-width: @screen-lg) {
        height: 304px;
      }

      padding: 10px;

      @media screen and (min-width: @screen-sm) {
        padding: 29px;
        padding-top: 25px;
      }

      p {
        position: relative;
      }


      font-size: @font-size-small;
      position: relative;
      overflow: hidden;
      transition: opacity 0.5s ease, background-color 0.5s ease;

      .carousel {

        .carousel-control {
          height: 35%;
          width: 5%;
        }

        margin-top: 20px;

        @media screen and (min-width: @screen-lg) {
          margin-top: 50px;
        }

        @media screen and (max-width: @screen-sm-1) {
          padding-left: 35px;
          padding-right: 35px;
        }


        &, .carousel-inner {

          @media screen and (max-width: @screen-sm-1) {
            min-height: 30px;
          }

          @media screen and (min-width: @screen-md) {
            height: 75px;
          }
          @media screen and (min-width: @screen-lg) {
            height: 115px;
          }
        }

        .item {

          .row {
            display: table;

            > div {
              float: none;
              display: table-cell;
              vertical-align: middle;
            }
          }

          img {

            width: 100%;
            height: auto;

            @media screen and (min-width: @screen-lg) {
              width: inherit;
            }

          }
        }
      }


      .row.hoverwhite {

        margin-bottom: 5px;
        padding-bottom: 0;
        padding-top: 10px;


        margin-top: -10px;
        margin-left: -30px;
        padding-left: 15px;
        margin-right: -30px;
        padding-right: 15px;

        &:first-of-type {
          margin-top: 0;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.3);

          div {
            text-decoration: underline;
          }

        }

        transition: all 0.2s;


        @media screen and (min-width: @screen-lg) {
          margin-bottom: 15px;

        }

      }

      &.content_clone .slide {
        overflow-y: auto;
        height: 100%;
        overflow-x: hidden;

        ul li {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      .row.metroslides {
        &:hover {

          div {
            color: @brig_blue_dark;
          }
        }

        div:nth-child(2) {
          font-size: 20px;
        }

        div:not(.slide):hover {
          cursor: pointer;
        }

        .slide {
          display: none;
        }
      }

      span.more {
        opacity: 0;
        display: block;
        transition: opacity 0.5s ease;
        position: absolute;
      }

      &.content_clone {
        h3 {
          font-size: @font-size-base;
          font-weight: bold;
        }

        .closeslide:hover {
          cursor: pointer;
        }
      }

      h3 {
        margin: 0;
        font-size: 20px;

        @media screen and (min-width: @screen-lg) {
          font-size: 25px;
        }

        @media screen and (max-width: @screen-sm-1) {
          font-size: 16px;
        }
      }

      .subhead {

        @media screen and (min-width: @screen-lg) {
          font-size: @font-size-base;
        }

        @media screen and (max-width: @screen-sm-1) {
          font-size: 11px;
        }

        font-weight: bold;
      }

      hr {
        border-top: 1px solid #bfceda;

        margin-bottom: 7px;
        margin-top: 7px;

        @media screen and (min-width: @screen-lg) {
          margin-top: 16px;
          margin-bottom: 16px;
        }
      }

      a {
        color: @grey58;

        &:hover {
          text-decoration: underline;
          color: @brig_blue_dark;
        }
      }

      img.inlineimage {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        padding-top: 5px;
      }

      img.inlineimage + * {
        margin-top: -5px;
      }

      img.image-center {
        position: absolute;
        top: 78px;
        left: 58px;

        @media screen and (min-width: @screen-md) {
          left: 10px;
          top: 90px;
        }


        @media screen and (min-width: @screen-lg) {
          left: 29px;
          top: 108px;
        }

        transition: all 0.5s ease;
        opacity: 1;
      }

      .metro-icon-bottom {
        position: absolute;
        font-size: 28px;
        left: 0;
        bottom: 0;
        line-height: 1;
        margin-bottom: 5%;
        color: #b6b6b6;
        padding-left: 10px;

        @media screen and (min-width: @screen-md) {
          padding-left: 29px;
        }
      }


      .metro-link {
        &:after {
          content: "\e106";
          font-family: "bridgingit";
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          font-size: 28px;
          right: 0;
          bottom: 0;
          margin-bottom: 5%;
          margin-right: 5%;
          color: #5a5a5a;

          @media screen and (max-width: @screen-sm-1) {
            border: 1px solid #5a5a5a;
            padding: 6px;
            font-size: 14px;
            font-weight: bold!important;
          }

        }

        &:hover:after {
          color: #428bca;
        }
      }

    }

    &.moretext:hover, &.imageblur:hover {

      &:not(.metro-image) {
        .content {
          background-color: #c3d7e6;
        }
      }


      &.moretext {
        span.more {
          opacity: 1;
        }
      }

      &.imageblur {
        img {
          -webkit-filter: blur(3px);
          -moz-filter: blur(3px);
          -o-filter: blur(3px);
          -ms-filter: blur(3px);
          filter: blur(3px);
          opacity: 0.5;
        }
      }



    }
  }

  .metro-white {
    .content {
      background-color: #edf1f9;

      .row.hoverwhite {
        padding-bottom: 10px;
        margin-bottom: 5px;

        &:hover {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }

  }

  .metro-blue {
    .content {
      background-color: #cdddeb;
    }
  }

  .metro-lightblue {
    .content {
      background-color: #dbe8f2;
    }
  }

  .metro-image {
    text-shadow: 0 0 0.1em black;
    color: #ffffff;

    > div {
      background: #37536a;
      margin: 2px;
    }

    .image {
      height: 170px;
      background-size: auto 170px;
      width: 154px;

      @media screen and (min-width: @screen-md) {
        width: 220px;
        height: 224px;
        background-size: auto 219px;

      }

      @media screen and (min-width: @screen-md) and (-webkit-min-device-pixel-ratio:0) {
        width: 219px;
      }


      @media screen and (min-width: @screen-lg) {
        width: 305px;
        height: 304px;

        background-size: auto 304px;
      }

      @media screen and (min-width: @screen-lg) and (-webkit-min-device-pixel-ratio:0) {
        width: 304px;
      }


      opacity: 0.6;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;

      transition: all 0.5s ease;
      overflow: hidden;
      top: 0;
      left: 0;
      margin: 2px;
    }

    &:hover {
      .image {
        opacity: 0.1;
      }

      .content *:not(.icon):not(.metro-link) {
        text-decoration: underline;
      }

    }

    .content {
      z-index: 10;

      > * {
        position: relative;
        z-index: 15;
      }

      a, a:hover, a *, a:hover * {
        color: #ffffff!important;
      }

      .metro-icon-bottom {
        color: #fff;
      }

      .metro-link {

        z-index: 10;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: block;

        &:after, &:hover:after {
          color: #fff;

          @media screen and (max-width: @screen-sm-1) {
            border: 1px solid #fff;
            padding: 6px;
            font-size: 14px;
            font-weight: bold!important;
          }

        }
      }

    }

  }


  // Overwrite for inner

  &.metrogrid-inner4 {

    .image {
      height: 170px;
      background-size: auto 170px;
      width: 100%;

      @media screen and (min-width: @screen-md) {
        width: 221px;
        height: 220px;
        background-size: auto 220px;

      }

      @media screen and (min-width: @screen-md) and (-webkit-min-device-pixel-ratio:0) {
        width: 220px;
      }


      @media screen and (min-width: @screen-lg) {
        width: 256px;
        height: 255px;
        background-size: auto 255px;
      }

      @media screen and (min-width: @screen-lg) and (-webkit-min-device-pixel-ratio:0) {
        width: 255px;
      }
    }


    .content {
      height: 170px;

      @media screen and (min-width: @screen-sm) {
        height: 220px;
      }

      @media screen and (min-width: @screen-lg) {
        height: 255px;
      }
    }
  }

  &.metrogrid-inner3 {

    .image {
      height: 170px;
      background-size: auto 170px;
      width: 100%;

      @media screen and (min-width: @screen-md) {
        width: 270px;
        height: 224px;
        background-size: auto 270px;

      }


      @media screen and (min-width: @screen-lg) {
        width: 313px;
        height: 304px;
        background-size: auto 314px;
      }

    }

  }

}

/* Topitem */
.top {

  background: #b6b6b6;
  @media screen and (max-width: @screen-sm-1) {
    margin-bottom: 30px;
  }

  hr {
    border-top: 1px solid #5f6060;
    border-color: rgba(209, 1, 38, 0.1);
    margin-top: 10px;
  }


  h3 {
    font-size: 25px!important;

    margin-left: -2px;
    margin-bottom: 5px;


    @media screen and (min-width: @screen-md) {
      font-size: 40px!important;
    }

    @media screen and (min-width: @screen-lg) {
      font-size: 50px!important;
    }
  }


  .headerbild {
    position: relative;
    overflow: hidden;
    width: 100%;

    .content {
      position: relative;
      margin-top: 15px;

      @media screen and (min-width: @screen-md) {
        margin-top: 60px;
      }


      .subhead {
        font-weight: bold;
        font-size: 11px;

        @media screen and (min-width: @screen-md) {
          font-size: 20px;
        }

        @media screen and (min-width: @screen-lg) {
          font-size: 25px;
        }
      }

    }

  }

  .carousel-control {
    span {
        background-color: rgba(255, 255, 255, 0.75);
        padding: 4px;
        padding-bottom: 0;
      color: #5f5f5f;
    }
  }

  .carousel[data-disable-mobile='true'] {
    .carousel-control {
      display: none!important;
    }
  }

  .carousel .carousel-inner .item, .headerbild {

      &, &.lg {
        height: 137px;

        @media screen and (min-width: @screen-md) {
          height: 376px;
        }


        @media screen and (min-width: @screen-lg) {
          height: 431px;
        }
      }

      &.md {
        height: 100px;

        @media screen and (min-width: @screen-md) {
          height: 330px;
        }


        @media screen and (min-width: @screen-lg) {
          height: 376px;
        }
      }

      &.xs {
        height: 70px;

        @media screen and (min-width: @screen-md) {
          height: 220px;
        }


        @media screen and (min-width: @screen-lg) {
          height: 250px;
        }
      }

      img {
        position: absolute;
        left: 50%;

        &.visible-lg {
          margin-left: -915px;
        }

        &.visible-md {
          margin-left: -640px;
        }

        &.visible-xs {
          margin-left: -320px;
        }

      }
  }
}

/* Carousel */
.carousel {

  .carousel-indicators {
    bottom: 0px;

    li {
      width: 39px;
      border: 0;
      background: #bbc5cc;
      height: 5px;
      margin: 1px;
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 0;

      transition: all 0.3s;

      &.active {
        background: @grey58;
      }

      &:hover {
        background: @brig_blue;
      }
    }
  }

  .carousel-control {
    background: transparent;
  }

  .carousel-inner {

    .item {
      img {
        height: 100%;
        margin: 0 auto;
        max-width: none;

      }
    }

    .carousel-content {
      width: 50%;

      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      text-align: center;

      height: 100%;

      &.container {
        text-align: left;
        width: 100%;
        height: 100%;

        padding-top: 20px;

        @media screen and (min-width: @screen-md) {
          padding-top: 60px;
        }
      }

      @media screen and (min-width: @screen-md) {
        height: 50%;
      }

      color: @grey58;
      font-weight: bold;

      font-size: 11px;

      @media screen and (min-width: @screen-md) {
        font-size: 20px;
      }



      @media screen and (min-width: @screen-lg) {
        font-size: 25px;
      }
      @media screen and (max-width: @screen-sm-1) {
        p {
          margin-bottom: 5px;
        }
      }


      > br {

        @media screen and (min-width: @screen-md) and (max-width: @screen-lg) {
          display: block;
          padding-bottom: 15px;
          content: " ";
        }
      }

      a.btn {

        font-weight: normal;
        font-size: 12px;
        @media screen and (min-width: @screen-lg) {
          font-size: 16px;
        }
      }


    }
  }
}

.maincontent {

  font-weight: 300;
  font-size: 12px;

  @media screen and (min-width: @screen-md) {
    padding-top: 35px;
    padding-bottom: 35px;
  }


  @media screen and (min-width: @screen-lg) {
    font-size: 14px;
    padding-left: 100px;
    padding-right: 100px;

    padding-top: 45px;
    padding-bottom: 45px;
  }

  margin-left: 0;
  margin-right: 0;


  // maincontent für schachtelelemente
  &.maincontent_leftright {
    @media screen and (min-width: @screen-md) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (min-width: @screen-lg) {
      padding-left: 115px;
      padding-right: 115px;

    }

  }

  // Inline für wenig abstand

  &.lineonly {
    padding-top: 35px;
    padding-bottom: 20px;

    @media screen and (max-width: @screen-sm-1) {
      padding-top: 5px;
    }
  }


  .maincontent {
    padding-bottom: 0;

    @media screen and (max-width: @screen-sm-1) {
      .collapse, .collapsing {
        margin-right: 0;
      }
    }
  }

  table.jobsummary {
    tr > td:first-of-type {
      width: 5%;
    }

    tr > td:nth-of-type(2) {
      width: 15%;
    }
  }

  &.testimonial img {
    width: 100%;
  }

  h5 {
    font-weight: bold;
    color: #5a5a5a;
  }

  .clientlist {
    img {
      padding: 5px;
    }
  }


  &.lightblue, &.blue {

    .container {
      max-width: @screen-xs;

      @media screen and (min-width: @screen-sm) {
        width: 897px; //-3px
        max-width: 897px;
      }

      @media screen and (min-width: @screen-lg) {
        width: 1036px; // -3px;
        max-width: 1036px;
      }
    }

  }

  &.lightblue {
    background-color: #f5f7fc;

    // Fix tabs
    .tabs {
      ul.nav {
        li.active a {
          background: 0;
          border-bottom: 1px solid #f5f7fc;
        }
      }
    }
  }

  &.blue {
    background-color: #c3d7e7;

    // Fix tabs
    .tabs {
      ul.nav {
        li.active a {
          background: 0;
          border-bottom: 1px solid #c3d7e7;
        }
      }
    }
  }

  /* Boxes for portfoliopage */
  .row.bluebox, .row.whitebox, .row.lightbluebox, .row.whitewhitebox, .row.middlebluebox {

    hr {
      border-top: 1px solid #cedae3;
    }

    @media screen and (min-width: @screen-sm) {
      margin-right: -12px;
    }

    div.center {
      text-align: center;
      margin-left: -10px;

      @media screen and (max-width: @screen-sm-1) {
        .subhead {
          font-weight: bold;
          font-size: 12px;
          font-family: @font-family-sans-serif;
        }
      }
    }


    > div, {
      padding-top: 30px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 30px;
      margin-right: 4px;
      height: 350px;

      @media screen and (min-width: @screen-sm) {
        height: 300px;
      }

      @media screen and (max-width: @screen-sm-1) {
        margin-top: -10px;
        height: auto;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.whitebox > div{
      background: #f5f7fc;
      height: auto;

      @media screen and (max-width: @screen-sm-1) {
        margin-bottom: -10px;
      }
    }

    &.whitewhitebox > div {
      background: #fafbfd;
    }

    &.lightbluebox > div {
      background: #eaf1f7;
    }

    &.bluebox > div {
      background: #dbe8f2;
    }

    &.middlebluebox > div {
      height: auto;
      background: #e8eff7;
    }

  }



  .inner {
    padding-top: 0;
  }

  .accordion .panel {
    border-radius: 0;
    margin-top: 0;


    &:not(:last-of-type) {
      border-bottom: 0;
    }

    .panel-heading {
      background: 0;
      padding: 20px;
      padding-left: 30px;

      &:hover {
        cursor: pointer;
      }

      .panel-title {

        position: relative;
        font-size: inherit;

        &:after {
          content: "\e04b";
          font-family: "bridgingit";
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          font-size: 20px;
          right: 0;
          top: 0;
          color: #5a5a5a;

          @media screen and (min-width: @screen-lg) {
            font-size: 25px;
          }

        }

        &.open {
          border-bottom: 0;
          background: none!important;

          &:after {
            content: "\e059";
          }
        }


        a {
          margin-right: 35px;
          display: block;

          &:hover {
            color: #3379bb;
          }
        }
      }
    }

    .panel-body {
      padding-top: 0px;
      padding-left: 30px;
      padding-right: 30px;
    }

    .collapse.in, .collapsing {
      .panel-body {
        border-top: 1px solid transparent;
      }

    }

  }

  .tabs {

    ul.nav {
      border-bottom: 0;

      a {
        border-radius: 0;
        font-weight: bold;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
      }


      @media screen and (max-width: @screen-sm-1) {
        li {
          width: 100%;
        }
      }


      li a {

          border: 1px solid #dadada;

        @media screen and (min-width: @screen-sm) {
          border-right: 0;
        }
          margin-right: 0;
      }

      li:last-of-type a {
          border-right: 1px solid #dadada;
      }

      li.active a {
        color: #3379bb;
        border-bottom: 1px solid transparent;
      }
    }

    .tab-content {
      padding: 30px;
      padding-bottom: 10px;
      padding-top: 10px;
      border: 1px solid #dadada;
    }
  }


  .collapse:not(.panel-collapse), .collapsing:not(.panel-collapse) {
    @media screen and (min-width: @screen-md) {
      display: block;
    }

    @media screen and (max-width: @screen-sm-1) {

      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      // padding-bottom: 10px;

      .subhead {
        font-size: 18px;
        font-family: 'Raleway', sans-serif;
        font-weight: 200;
      }
    }
  }

  h3 {
    margin-top: 0;
    font-size: 20px;
    @media screen and (min-width: @screen-lg) {
      font-size: 24px;
    }

    @media screen and (max-width: @screen-sm-1) {

      &.collapse_toggle {
        font-size: 14px;
        font-family: @font-family-sans-serif;
        font-weight: bold;

        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px;
        background: white;
        padding-bottom: 15px;
        margin-bottom: 0;

        border-top: 1px solid #e6e9eb;
        border-bottom: 1px solid #e6e9eb;

        &.mar_bot50 {
          margin-bottom: 0px;
        }

        &:last-of-type {
          border-bottom: 0;
        }

        &:after {
          content: "\e04b";
          font-family: "bridgingit";
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          font-size: 20px;
          right: 15px;
        }

        &.open {
          border-bottom: 0;
          background: none!important;

          &:after {
            content: "\e059";
          }
        }

        transition: background-color 0.5s;
      }


    }

  }

  span.subhead {
    display: block;
    margin-top: -5px;
    font-weight: bold;
    margin-bottom: 15px;

  }

  p {
    line-height: 1.75em;
    margin-top: -5px; // Line-height
  }

  ul.bigcheck {

    padding-left: 10px;

    li {

      &:before {
        content: "\e067";
        float: left;
        margin-left: -55px;

        font-family: "bridgingit";
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 20px;

      }
      background: 0;

      padding-left: 50px;
      padding-right: 30px;
    }
  }

  &.newsletter {


    padding-top: 10px;
    padding-bottom: 10px;

    img {
      width: 75%;
      margin-top: -10px;
    }

    @media screen and (min-width: @screen-md) {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -15px;
      img {
        margin-top: -45px;
        width: 100%;
      }
    }

    a[href^='mailto:'] {
      color: @text_gray!important;
      text-decoration: underline;

      &:hover {
        color: #3379bb!important;
      }
    }

    > .inner {
      @media screen and (min-width: @screen-md) {
        padding-top: 45px;
      }

      padding-bottom: 0;

    }

    @media screen and (max-width: @screen-sm-1) {
      h3 {
        font-size: 18px;
      }

      .subhead {
        font-size: 11px;
      }
    }

  }

  a:not(.btn) {
    color: @text_gray;

    &:hover {

      * {
        color: #3379bb;
        text-decoration: underline;
      }

    }
  }

  .arrowlink, .arrowlink-back {
    font-weight: bold;
  }

  .further {
    p {
      margin: 0;
      font-size: 12px;
    }
    strong {
      margin-bottom: 5px;
      display: block;
    }

    li strong {
      display: inline;
    }

    .arrowlink {
      font-size: 12px;
      padding-left: 15px;
      background-position-y: 1px;
    }
  }

}

/* Quote */

div.quote {

  &:before {
    display: block;
    content: url(../img/quote.png);
    margin-bottom: 5px;
  }

  span.from {
    font-style: italic;
    @media screen and (min-width: @screen-md) {
      display: block;
      margin-top: -5px;
    }
  }
}

.breadcrumb {
  background: 0;
  padding: 0;
  font-size: 12px;
  margin-bottom: 10px;

  > li + li:before {
    content: ">";
    color: #5a5a5a;
  }
}

ul.jobsfilter {

  padding: 0;

  li {

    display: block;
    float: left;
    position: inherit;
    padding: 10px;

    &:after {
      content: "\e107";
      font-family: "bridgingit" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      speak: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      margin-left: 3px;
    }


    div.checkboxes {

      background-color: #e8eff7;
      opacity: 0;
      max-height: 0px;
      overflow: hidden;

      position: absolute;
      left: 0;
      top: 37px;
      z-index: 20;
      height: auto;

      transition: all 0.25s ease-in-out;

      margin-left: 15px;
      margin-right: 15px;
      width: 100%;


      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: @screen-sm-1) {
        width: 90%;
      }


      .checkbox {
        margin-bottom: 0;
        margin-top: 10px;

        &:last-of-type {
          margin-bottom: 10px;
        }
      }

      .checkbox + .checkbox {
        margin-top: 10px;
      }
    }

    @media screen and (min-width: @screen-md) {
      &:hover{
        background-color: #e8eff7;
        strong, &:after {
          color: #0066b0;
        }
      }

      &:hover > .checkboxes{
        opacity: 1;
        max-height: 400px;
      }
    }



    // Click for mobile
    @media screen and (max-width: @screen-sm-1) {
      &.open{
        background-color: #e8eff7;
        strong, &:after {
          color: #0066b0;
        }
      }

      &.open > .checkboxes{
        opacity: 1;
        max-height: 400px;
      }
    }
  }
}


/* Buttonmenu */

.nav.nav-pills.buttonmenu, .nav.pagination {

  padding-bottom: 20px;

  @media screen and (max-width: @screen-sm-1) {
    padding-bottom: 10px;
  }

  a {
    border: 1px solid #aeaeae;
    border-radius: 0!important;
    color: #aeaeae;
    padding: 3px 10px;
    margin-right: 4px;

    &:hover, &:hover span {
      color: #fff;
      background-color: #015ea9;
      text-decoration: none;
    }

    span.icon {
      margin-left: -4px;
      margin-right: -4px;
      display: block;
      padding-top: 2px;
      margin-bottom: -2px;
    }
  }

  li.active {
    a {
      color: #000;
      border: 1px solid #000;
      background-color: #fff;
    }
  }

}

/************Section BLOG *******************************/
.blog article img {
    width: 100%;
}

@media (max-width: 1023px) {
    .blog article img {
        width: 50%;
        margin-bottom: 15px;
    }
}
@media (max-width: 767px) {
    .blog_post img {
        margin-bottom: 15px;
        width: 60%;
    }
}
@media (min-width: 768px) {
    .blog_post img {
        float: left;
        margin-right: 15px;
    }
}

.full_width {
    width: 100%;
}

.nomarging_top {
    margin-top: 0;
}

.nopadding_bottom {
    margin-bottom: 0;
}

/*FB Carousel*/

#facebook-carousel {
    margin-top:0;
    height: 90%;
}

#facebook-carousel .carousel-inner {
    height: 90%;
}

.facebook-index-carousel-img {
    width: 100%;
    padding-left:33%;
    padding-right: 33%;
    height: 95px;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media (max-width: 1279px){
    #facebook-carousel {
        height: 97%;
    }
    .facebook-index-carousel-img {
        height: 60px;
    }
}
@media (max-width: 767px){
    #facebook-carousel {
        height: 90%;
    }
    .facebook-index-carousel-img {
        padding-left:30%;
        padding-right: 30%;
        margin-bottom: 5px;
    }
    .item-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.facebook-index-carousel-img img {
    align-self: center;
    vertival-align: middle;
}


.youtube-video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    margin-bottom: 30px;
    height: 0;
}

.youtube-video > iframe, .youtube-video > iframe * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* Import from http://www.bridging-it.de/content/referenzen/referenzen.html */


ul.jobsfilter li.pull-right {
    background-color: #fff !important;
}
ul.jobsfilter li.pull-right a {
    padding:0 !important;
    color: #5a5a5a !important;
}
ul.jobsfilter li.pull-right:hover a, ul.jobsfilter li.pull-right a:hover {
    background-color: #fff !important;
}
.jobsfilter li.pull-right a:hover {
    Text-decoration:underline !important;
    background-color: #fff !important;
}

ul.jobsfilter li.pull-right:after  {
    display: none !important;
}

ul.jobsfilter {
    padding-right: 100px !important;
}

ul.jobsfilter {
    padding: 0;
}
ul.jobsfilter li {
    display: block;
    float: left;
    position: inherit;
    padding: 10px;
}
ul.jobsfilter li:after {
    content: "\e107";
    font-family: "bridgingit" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    margin-left: 3px;
}
ul.jobsfilter li div.checkboxes {
    background-color: #dbe8f2;
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 37px;
    z-index: 20;
    height: auto;
    transition: all 0.25s ease-in-out;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 1023px) {
    ul.jobsfilter li div.checkboxes {
        width: 90%;
    }
}
ul.jobsfilter li div.checkboxes .checkbox {
    margin-bottom: 0;
    margin-top: 10px;
}
ul.jobsfilter li div.checkboxes .checkbox:last-of-type {
    margin-bottom: 10px;
}
ul.jobsfilter li div.checkboxes .checkbox + .checkbox {
    margin-top: 10px;
}
@media screen and (min-width: 1024px) {
    ul.jobsfilter li:hover {
        background-color: #dbe8f2;
    }
    ul.jobsfilter li:hover strong,
    ul.jobsfilter li:hover:after {
        color: #7396C8;
    }
    ul.jobsfilter li:hover > .checkboxes {
        opacity: 1;
        max-height: 400px;
    }
}
@media screen and (max-width: 1023px) {
    ul.jobsfilter li.open {
        background-color: #dbe8f2;
    }
    ul.jobsfilter li.open strong,
    ul.jobsfilter li.open:after {
        color: #7396C8;
    }
    ul.jobsfilter li.open > .checkboxes {
        opacity: 1;
        max-height: 400px;
    }
}

@media screen and (min-width: 1280px) {

    .maincontent_filter {
        padding-left: 100px;
    }
}

/** NEU 26.07 **/

@media screen and (min-width: @screen-sm) {

    .pad_leftright15 {
        padding-left: 15px;
        padding-right: 15px;
    }

}
.h3_sm {
    font-size: 20px;
    margin-top: 0;

    &.padleft100 {
        @media screen and (max-width: @screen-xs-max) {
            padding-left: 15px;
        }

        @media screen and (min-width: @screen-lg) {
            padding-left: 100px;
        }
    }
}

.maincontent.maincontent_sm {
    padding-top: 10px;
    padding-bottom: 10px;

    @media screen and (max-width: @screen-xs-max) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.vcenter {
    display:table-cell;
    vertical-align:middle;
    float:none;
}

.border_bot {
    margin-top: 0;
    border-top: 0;
    border-bottom: 1px solid #e6e9eb;
}

.pos_rel {
    position: relative;
}


.saleskachel {

    @media screen and (max-width: @screen-xs-max) {
        &:nth-child(odd){

            clear:left;
            .saleskachel-content {
                width: 94vw;
                max-width: 632px;
                left: -15px;
            }
        }

        &:nth-child(even) .saleskachel-content {
            width: 94vw;
            max-width: 632px;
            left: ~"calc(-51vw - 2px)";
        }
    }

    @media screen and (min-width: 650px) and (max-width: @screen-sm-max) {
        &:nth-child(even) .saleskachel-content {
            left: -333px;
        }
    }


    &, .saleskachel-content {
        background-color: #dbe8f2;
    }

    &:nth-child(even){

        &, .saleskachel-content {
            background-color: #cdddeb;
        }
    }

    border: 2px solid #fff;

    @media screen and (min-width: @screen-sm) {
        // Desktop 3er
        &:nth-child(3n +1){
            border-left: 0;
            clear:left;
        }

        &:nth-child(3n +3){
            border-right: 0;
        }
    }


    .saleskachel-icon:after {
        content: '';
        width: 111%;

        @media screen and (min-width: @screen-lg) {
            width: 109.5%;
        }

        @media screen and (max-width: @screen-xs-max) {
            width: ~"calc(100% + 30px)";
        }

        position: absolute;
        left: -15px;
        bottom: -3px;
        height: 0;
        transition: height 0.25s;
        background-color: #dbe8f2;
    }

    &:nth-child(even){
        .saleskachel-icon:after{
            background-color: #cdddeb;
        }
    }

    &.open .saleskachel-icon:after {
        height: 3px;
    }


    .saleskachel-icon {
        padding-top: 30px;
        padding-bottom: 30px;
        height: 162px;
        position: relative;

        &:hover {
            cursor: pointer;

            h3 {
                text-decoration: underline;
            }

            span.icon:before {
                transform: scale(1.3) translateZ(0);
            }
        }

        &, h3 {
            font-size: 26px;
        }

        h3 {
            padding-top: 4rem;
            @media screen and (max-width: @screen-xs-max) {
                padding-top: 2rem;

            }
        }

        span.icon:before {
            position: absolute;
            margin-left: -1rem;
            left: 50%;
            transform: translateZ(0);
            transition: transform 0.3s ease-out;
        }
    }



    .saleskachel-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s ease-out, margin 0.1s ease-out;
        position: relative;
        width: 336%;
        left: -15px;
        top: 2px;


        @media screen and (min-width: @screen-lg) {
            width: ~"calc(330% + 2px);";
        }

        .saleskachel-content-inner {
            padding: 30px;
            padding-top: 60px;
        }

        .btn {
            position: absolute;
            right: 0;
            top: 10px;
            outline: none;

            font-size: 26px;

            &, &:hover {
                color: #5a5a5a;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }


    @media screen and (min-width: @screen-sm) {
        &:nth-child(3n +2){
            .saleskachel-content{
                left: -315px;
                width: 338%;
            }
        }

        &:nth-child(3n +3){
            .saleskachel-content{
                left: -615px;
                width: 335%;
            }
        }
    }

    @media screen and (min-width: @screen-lg) {
        &:nth-child(3n +2){
            .saleskachel-content{
                left: -362px;
                width: ~"calc(333% - 2px)";
            }
        }

        &:nth-child(3n +3){
            .saleskachel-content{
                left: -707px;
                width: 330%;
            }
        }
    }



    &.open .saleskachel-content {
        max-height: 1000px;
        transition:  max-height 0.5s ease-in, margin 0.1s ease-in;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .arrowlink {
        font-weight: normal;
    }

    @media screen and (max-width: @screen-xs-max) {
        .saleskachel-icon {
            padding-top: 20px;
            padding-bottom: 20px;
            height: 100px;

            &, h3 {
                font-size: 16px;
            }
        }

        .saleskachel-content {

            left: 4px;
            width: ~"calc(100% - 8px)";

            .btn {
                font-size: 16px;
            }
        }

        & {
            border-left-width: 4px;
            border-right-width: 2px;

            &:nth-child(even){
                border-left-width: 2px;
                border-right-width: 4px;
            }

        }
    }
}

.carouselhi {

    @media screen and (max-width: @screen-xs-max) {
        padding-left: 10%;
        padding-right: 10%;
    }

    .carousel-inner {
        height: 120px;

        @media screen and (max-width: @screen-xs-max) {
            height: auto;
        }

        .row {
            display: table;
            width: 100%;

            > div {
                display: table-cell;
                vertical-align: middle;
                float: none;
                text-align: center;

                img {
                    height: auto;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

a.left.carousel-control, a.right.carousel-control {

    > span {
        top: 40%
    }

    &:hover *{
        text-decoration: none;
    }
}

/** ENDE NEU 26.07 **/
